import React, { useEffect, useState } from "react";
import { fetchApi } from "../../redux/api";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
    const user = useSelector((state) => state.auth.user.username);

  useEffect(() => {
  fetchApi(`notifications?type=fetchAllNotifications&mda_name=${user}`).then((response) =>
    setNotifications(response.results)
  )
  },[]);


  return (
    <div className="container card">
      <div className="">
        <h2 className="text-center">Notifications</h2>
        <Row className="align-item-center flex-column">
    
        {notifications?.map((notification) => (
          <Col className="ps-0 ms--2 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{notification.notification_type}</h4>
              </div>
              <div className="text-end">
                <small className={'text-danger'}>
                  {moment(notification.created_at).calendar()}
                </small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{notification.message}</p>
          </Col>
        ))}
        </Row>
      </div>
    </div>
  );
};

export default Notifications;
