/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import NewPassword from "./pages/examples/NewPassword";

export const Routes = {
  tsafunding: {
    faacTsaFunding: "/tsa-funding/faac",
    faacTsaFundingWithParams: "/tsa-funding/faac/:types",
    newfunding: "/tsa-funding/new",
    newfundingWithParams: "/tsa-funding/new/:types",
    fundingReport: "/tsa-funding/report",
    fundingReportWithParams: "/tsa-funding/report/:account_code/:account_title",
    key: "/tsa-funding",
  },

  budget: {
    budgetUtlization: "/budget-utilization/index",
    budgetDashboard: "/budget-utilization/dashboard",
    uploadBudget: "/budget-utilization/upload-budget",
    budgetUtlizationReport: "/budget-utilization/report",
    budgetUtlizationReportDynamicRoute: "/budget-utilization/report/:mda_name",
  },

  account: {
    // manageAccount: "/account-management/index?type=all",
    manageAccountMain: "/account-management/index",
    manageAccountMainDynamic: "/account-management/index/:type",
    // manageAccountSub: "/account-management/index",
    // manageAccountSubDynamic: "/account-management/index/:type",
    // manageAccountOthers: "/account-management/index",
    // manageAccountOthersDynamic: "/account-management/index/:type",
    accountForm: "/account-management/edit",
    accountReport: "/account-management/report",
    viewAccount: "/account-management/info",
    viewAccountDynamic: "/account-management/info/:id",
  },

  bank: {
    bankIndex: "/bank/index",
    editBank: "/bank/edit",
    editBankDynamicRoute: "/bank/edit/:id",
    UserProfile: "user/profile",
    UserProfileRouteDynamic: "user/profile/:id",
  },

  // profile: {
  //   UserProfile: "user/profile",
  // },

  approval: {
    approvalIndex: "/approval-collection/index",
    newApproval: "/approval-collection/new",
    pendingApproval: "/approval-collection/PendingApproval",
    agApprovalDashboard: "/approval-collection/ag-dashboard",
    agApprovedReleases: "/approval-collection/ag-approved",
    auditorGeneralApprovalDashboard:
      "/approval-collection/auditor-general-dashboard",
    adExpContrReleases: "/approval-collection/ad-expenditure-control",
    editApprovalRouteDynamic: "/approval-collection/edit/:id",
    editApprovalRouteDynamicAg: "/approval-collection-ag-view/edit/:id",
    editApprovalRoute: "/approval-collection/edit",
    editApprovalRouteAg: "/approval-collection-ag-view/edit",
    AuditorGeneraleditApprovalRouteDynamic:
      "/auditor-general/approval-collection/edit/:id",
    AuditorGeneraleditApprovalRoute:
      "/auditor-general/approval-collection/edit",
    reviewReleases: "/approval-collection/ReviewReleases",
    approvalReleases: "/approval-collection/ApprovalReleases",
    agReleases: "/approval-collection/AgReleases",
  },

  pv: {
    pvlIndex: "/pv-collection/index",
    pvCheckIndex: "/pv-collection/check-index",
    pvADCheckIndex: "/pv-collection/checkad-index",
    pvReviewIndex: "/pv-collection/review-index",
    pvApproveIndex: "/pv-collection/approve-index",
    pvReports: "/pv-collection/reports",
    auditorpvReports: "/auditor-pv-collection/reports",
    newPv: "/pv-collection/new",
    newPvDynamic: "/pv-collection/new/:approval_no",
    editnewPv: "/pv-collection/edit",
    editnewPvDynamic: "/pv-collection/edit/:pv_code",
    reviewPvDynamic: "/pv-collection/review/:approval_no",
    approvePvDynamic: "/pv-collection/approve/:approval_no",
    editPv: "/pv-collection/new/:approval_no",

    viewPvReview: "/pv-pdf/review",
    viewPvReviewDynamic: "/pv-pdf/review/:pv_code",

    viewPvGenerated: "/pv-pdf/view-generated",
    viewPvGeneratedDynamic: "/pv-pdf/view-generated/:pv_code",

    editPv: "/pv-pdf/edit",
    editPvDynamic: "/pv-pdf/edit/:pv_code",

    viewPvCheck: "/pv-pdf/check",
    viewPvCheckDynamic: "/pv-pdf/check/:pv_code",

    viewPvADCheck: "/pv-pdf/checkad",
    viewPvADCheckDynamic: "/pv-pdf/checkad/:pv_code",

    viewPvApprove: "/pv-pdf/approve",
    viewPvApproveDynamic: "/pv-pdf/approve/:pv_code",
    pvIndividualView: "/pv-collection-individual/view",
    pvIndividualViewDynamic: "/pv-collection-individual/view/:pv_code",
    pvReportView: "/pv-collection-report/view",
    pvReportViewDynamic: "/pv-collection-report/view/:pv_code",
    AuditorpvReportView: "/auditor-pv-collection-report/view",
    AuditorpvReportViewDynamic: "/auditor-pv-collection-report/view/:pv_code",
    UserProfile: "/user/profile",
  },

  expenditureControl: {
    receivingOfficeIndex: "/receiving-office/index",
    receivingOfficeView: "/receiving-office/view",
    receivingOfficeViewDynamic: "/receiving-office/view/:approval_no",

    dirExpIndex: "/dir-exp-control/index",
    dirExpFinalIndex: "/dir-exp-final-control/index",
    dirExpPaymentScheduleIndex: "/dir-exp-control/payment-schedule/index",

    dirExpView: "/dir-exp-control/view",
    dirExpFinalView: "/dir-exp-final-control/view",
    dirExpViewDynamic: "/dir-exp-control/view/:approval_no",
    dirExpFinalViewDynamic: "/dir-exp-final-control/view/:approval_no",

    dirExpPaymentScheduleView: "/dir-exp-control/payment-schedule/view",
    dirExpPaymentScheduleViewDynamic:
      "/dir-exp-control/payment-schedule/view/:batch_no",

    assDirExpIndex: "/assistant-director-exp-control/index",
    assDirExpView: "/assistant-director-exp-control/view",
    assDirExpViewDynamic: "/assistant-director-exp-control/view/:approval_no",

    assDirFinalExpIndex: "/assistant-director-exp-final-control/index",
    assDirFinalExpView: "/assistant-director-exp-final-control/view",
    assDirFinalExpViewDynamic:
      "/assistant-director-exp-final-control/view/:approval_no",

    assDirPsIndex: "/assistant-director-exp-control/payment-schedule/index",
    assDirPSView: "/assistant-director-exp-control/payment-schedule/view",
    assDirPSViewDynamic:
      "/assistant-director-exp-control/payment-schedule/view/:batch_no",

    expControlIndex: "/expenditure-control/index",
    expControlView: "/expenditure-control/view",
    expControlViewDynamic: "/expenditure-control/view/:approval_no",
    expControlScheduleIndex: "/expenditure-control/schedule",
    expControlScheduleView: "/expenditure-control/schedule-view",
    expControlScheduleViewDynamic:
      "/expenditure-control/schedule-view/:approval_no",
  },

  dag: {
    dagIndex: "/dag-office/payment-schedule/index",
    dagApprovalView: "/dag-office/payment-schedule/view",
    dagApprovalViewDynamic: "/dag-office/payment-schedule/view/:batch_no",

    dagPendingPVIndex: "/dag-office/pending-pv/index",
    dagPendingPVView: "/dag-office/pending-pv/view",
    dagPendingPVViewDynamic: "/dag-office/pending-pv/view/:approval_no",
  },

  commissioner_dashboard: {
    commissionerDashboardIndex: "/commissioner-dashboard",
    commissionerDashboardPVDynamic:
      "/commissioner-dashboard/commissioner-view/:pv_code",
    commissionerDashboardPV: "/commissioner-dashboard/commissioner-view",
    psDashboard: "/ps-dashboard",
    PSDashboardPVDynamic: "/ps-dashboard-view/:pv_code",
    PSDashboardPV: "/ps-dashboard-view",
  },

  paymentRequest: {
    raisePaymentRequest: "/payments/raise-request",
    reviewPaymentRequest: "/payments/review-request",
    viewPaymentRequestDetail: "/payments/view-request-detail",
    directTransfer: "/payments/direct-transfer",
    viewPaymentRequestDetailDynamic: "/payments/view-request-detail/:requestId",
    viewPaymentRequestDetailFinal: "/payments/view-request-detail-final",
    viewPaymentRequestDetailFinalDynamic:
      "/payments/view-request-detail-final/:requestId",
    approvePaymentRequest: "/payments/approve-request",
    directTransferList:'/payment/direct-transfer-list'
  },

  cashBacking: {
    cashBackinReview: "/cash-backing-review",
    cashBackinPending: "/cash-backing-pending",
    cashBackinApproved: "/cash-backing-approved",
    cashBackingIndex: "/cash-backing/index",
    viewPV: "/pv-collection/view",
    cashBackingPDF: "/cash-backing/view-pdf",
    cashBackingPDFDynamic: "/cash-backing/view-pdf/:approval_no",
    processCashBacking: "/cash-backing/process-cash-backing",
    processCashBackingDynamic:
      "/cash-backing/process-cash-backing/:approval_no",
    reviewProcessCashBacking: "/cash-backing/review-process-cash-backing",
    reviewProcessCashBackingDynamic:
      "/cash-backing/review-process-cash-backing/:approval_no",
    approvedProcessCashBacking: "/cash-backing/approved-process-cash-backing",
    approvedProcessCashBackingDynamic:
      "/cash-backing/approved-process-cash-backing/:approval_no",

    NewViewPVDynamic: "/new-pv-collection/view/:pv_code",
    NewViewPVDynamicPdf: "/pdf-pv-collection/view/:pv_code",
    NewViewPV: "/new-pv-collection/view",
    NewViewPVPDf: "/pdf-pv-collection/view",
    viewPVDynamic: "/pv-collection/view/:pv_code",
    cashBackingRequest: "/pv-collection/cash-backing-request",
    ViewCashBackingRequest: "/pv-collection/view-cash-backing-request",
    ViewCashBackingRequestDynamic:
      "/pv-collection/view-cash-backing-request/:approval_no",
    cashBackingRequestPDF: "/pv-collection/cash-backing-request/pdf",
    releaseCollectionPDF: "/pv-collection/release-collection/pdf",
    releaseCollectionPDFDynamic:
      "/pv-collection/release-collection/pdf/:approval_no",
    // NewViewPV: "/new-pv-collection/view",
  },

  // sub-treasury
  subTreasury: {
    receivingOfficeIndex: "/sub-treasury/receiving-office/index",
    receivingOfficeViewSchedule: "/sub-treasury/receiving-office/view-schedule",
    receivingOfficeViewScheduleDynamic:
      "/sub-treasury/receiving-office/view-schedule/:batch_no",
    checkingOfficeIndex: "/sub-treasury/checking-office/index",
    checkingOfficeViewSchedule: "/sub-treasury/checking-office/view-schedule",
    checkingOfficeViewScheduleDynamic:
      "/sub-treasury/checking-office/view-schedule/:batch_no",
    dstOfficeIndex: "/sub-treasury/dst-office/index",
    dstOfficeViewSchedule: "/sub-treasury/dst-office/view-schedule",
    dstOfficeViewScheduleDynamic:
      "/sub-treasury/dst-office/view-schedule/:batch_no",

    dstPendingScheduleOfficeIndex: "/sub-treasury/dst-pending-schedule/index",
    dstPendingScheduleOfficeViewSchedule:
      "/sub-treasury/dst-pending-schedule/view-schedule",
    dstPendingScheduleOfficeViewScheduleDynamic:
      "/sub-treasury/dst-pending-schedule/view-schedule/:batch_no",

    headChequesOfficeIndex: "/sub-treasury/head-cheques-office/index",
    headChequesOfficeViewSchedule:
      "/sub-treasury/head-cheques-office/view-schedule",
    headChequesOfficeViewScheduleDynamic:
      "/sub-treasury/head-cheques-office/view-schedule/:batch_no",

    subTreasurerPaymentReviewOfficeIndex:
      "/sub-treasury/sub-treasurer-payment-review/index",
    subTreasurerPaymentReviewOfficeViewSchedule:
      "/sub-treasury/sub-treasurer-payment-review/view-schedule",
    subTreasurerPaymentReviewOfficeViewScheduleDynamic:
      "/sub-treasury/sub-treasurer-payment-review/view-schedule/:batch_no",

    approvedPaymentsIndex: "/sub-treasury/approved-payments/index",
    approvedPaymentsViewSchedule:
      "/sub-treasury/approved-payments/view-schedule",
    approvedPaymentsViewScheduleDynamic:
      "/sub-treasury/approved-payments/view-schedule/:batch_no",
  },

  paymentSchedule: {
    index: "/payment-schedule/index",
    capital: "/payment-schedule/capital",
    recurrent: "/payment-schedule/recurrent",
    viewSchedule: "/payment-schedule/view",
    viewScheduleDynamic: "/payment-schedule/view/:batch_no",
    newSchedule: "/payment-schedule/new",
    newScheduleWithApproval: "/payment-schedule/new/:approval_no",
    // "/payment-schedule/new/:mda_code/:name/:economic_code/:approval_no/:imageId/:description_code/:mda_name/:amount/:narration",
    // newScheduleWithoutApproval: '/payment-schedule/:name',
    key: "payment-schedule",

    stIndex: "/contractor-schedule/index",
    newStSchedule: "/contractor-schedule/new",
    viewStSchedule: "/contractor-schedule/view",
    viewStScheduleDynamic: "/contractor-schedule/view/:contractor_code",
    viewStScheduleDetails: "/contractor-schedule/view-tax-details",
    viewStScheduleDetailsDynamic: "/contractor-schedule/view-tax-details/:id",
    paymentSchedulePdf: "/contractor-schedule/payment-schedule/pdf",
    paymentSchedulePdfDynamic:
      "/contractor-schedule/payment-schedule/pdf/:batch_no",
  },

  contractorSchedule: {
    index: "/initiate-payment/index",
    processRecurrent: "/initiate-payment/recurrent",
    processRecurrentDynamic: "/initiate-payment/recurrent/:approval_no",
    processCapital: "/initiate-payment/capital",
    processCapitalDynamic: "/initiate-payment/capital/:approval_no",
    newSchedule: "/contractor-schedule/new",
    // newScheduleDynamic: '/contractor-schedule/new',
  },

  batchProcessing: {
    index: "/batch-processing/index",
    process: "/batch-processing/process-payment",
    processWithParams: "/batch-processing/process-payment/:batch_no",
    update: "/batch-processing/update-payment",
    viewPaymentList: "/batch-processing/update-payment/view-payment-list",
    viewPaymentListWithParams:
      "/batch-processing/update-payment/view-payment-list/:batch_no",
    processUpdate: "/batch-processing/update-payment/update",
    processUpdateWithParams:
      "/batch-processing/update-payment/update/:batch_no",
  },

  payments: {
    index: "/commit-payment/index",
    process: "/commit-payment/process",
    processWithParams: "/commit-payment/process/:batch_no",

    viewPDF: "/commit-payment/process/view-pdf",
    viewPDFWithParams: "/commit-payment/process/view-pdf/:id",

    viewStPDF: "/commit-payment/process/view-st-pdf",
    viewStPDFWithParams: "/commit-payment/process/view-st-pdf/:id",
  },

  remittance: {
    index: "/remittance/index",
    process: "/remittance/process",
    processWithParams: "/remittance/process/:batch_no",

    viewPDF: "/remittance/process/view-pdf",
    viewPDFWithParams: "/remittance/process/view-pdf/:batch_no",

    paymentAdviceIndex: "/remittance/payment-advice/index",
    viewPaymentAdvice: "/remittance/process/view-payment-advice-pdf",
    viewPaymentAdviceWithParams:
      "/remittance/process/view-payment-advice-pdf/:batch_no",
  },

  reports: {
    expenditureReport: "/reports/expenditure-report",
  },

  userAdmin: {
    index: "/user-admin/index",
    editUser: "/user-admin/edit",
    editUserDynamicRoute: "/user-admin/edit/:id",
  },
  auditordashboard: {
    auditorIndex: "/adminauditoring",
  },
  taxSetup: {
    taxIndex: "/taxsetup",
  },
  // pages
  Presentation: { path: "/" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  NewPassword: { path: "/examples/new-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
  Notifications: { path: "/dashboard/notifications" },
};
