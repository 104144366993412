class WebSocketService {
  constructor() {
    this.socket = null;
    this.onNotification = () => {};
    this.onAllNotifications = () => {};
    this.onNotificationRead = () => {};
  }

  connect(userId,mda_name) {
    this.socket = new WebSocket("ws://localhost:45459");

    this.socket.onopen = () => {
      // console.log("Connected to WebSocket server", userId);
        this.socket.send(JSON.stringify({ type: "connect", userId }));

      this.socket.send(JSON.stringify({ type: "fetchNotifications", userId, mda_name }));
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // console.log(data)
      if (data.type === "notification") {
        this.onNotification(data.notification);
      } else if (data.type === "allNotifications") {
        this.onAllNotifications(data.notifications);
      } else if (data.type === "notificationRead") {
        this.onNotificationRead(data.id);
      }
    };
  }

  subscribeToNotifications(callback) {
    this.onNotification = callback;
   
  }

  subscribeToAllNotifications(callback) {
    this.onAllNotifications = callback;
  }

  notificationRead(callback) {
    this.onNotificationRead = callback;
  }

  sendNotification(notification) {
    this.socket.send(
      JSON.stringify({ type: "sendNotification", notification })
    );
  }

  markAsRead(notificationId) {
    console.log('called')
    this.socket.send(JSON.stringify({ type: "markAsRead", notificationId }));
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export default new WebSocketService();
