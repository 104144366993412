import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Form, Modal, Row } from "react-bootstrap";
import CustomTypeahead from "../../components/CustomTypeahead";
import { createTokenPin, fetchApi, postApi } from "../../../redux/api";
import { accountTypes, bankList } from "../../accounts/AccountForm";
import SelectInput from "../../../components/SelectInput";
import TextInput from "../../../components/TextInput";
import CustomButton from "../../../components/CustomButton";
import { formatNumber } from "../../../redux/utils";
import moment from "moment-timezone";
import toast from "react-hot-toast";
import BackButton from "../../../components/BackButton";
import { useNavigate } from "react-router-dom";

function DirectTransfer() {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const toggle1 = () => setModalIsOpen1((p) => !p);
  const history = useNavigate();
  const [form, setForm] = useState({
    ben_dec: null,
    ben_acc: null,
    ben_bank: "",
    account_number: null,
    account_type: null,
    bank_code: null,
    amount: null,
    account_name: null,
    type: null,
    title: null,
    description: null,
    beneficiary_name: null,
    ben_acc_type: "Default",
  });
  const [pin, setPin] = useState("");
  useEffect(() => {
    setIsLoading(true);
    fetchApi("pan_details/get")
      .then((resp) => {
        setIsLoading(false);
        if (resp) {
          setList(resp.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  const options = bankList.map((item) => item.title);
  const accTypes = accountTypes.map((item) => item.title);
  const processPayment = () => {
    if (pin.length < 4) {
      alert("PIN is 4 digit");
    } else {
      setLoading(true);
      const finalList = [];
      [form].forEach((payment) => {
        let new_amount = payment.amount * 100;
        let input_amount = new_amount?.toString().split(".")[0];
        finalList.push({
          amount: input_amount,
          narration: form.description.replace("'", ""),
          beneficiary_code: "Direct0001",
          ben_email: "",
          ben_bank_cbn_code:
            bankList.find((i) => i.title === form.bank_code)?.code || "044",
          ben_account_no: form.ben_acc,
          account_type:
            accountTypes.find((i) => i.title === form.ben_acc_type)?.code ||
            "10",
          is_prepaid_load: "FALSE",
          currency: "NGN",
          beneficiary_name: form.beneficiary_name?.replace("'", ""),
          beneficiary_mobile_no: "",
          type: 1,
        });
      });

      postApi("autogate/single-transfer", {
        details: finalList,
        description: "Payment",
        id: form.id,
        vPinToken: createTokenPin({ vPinToken: pin }),
        form,
      })
        .then((res) => {
          if (res.success) {
            toggle1();
            toast.success(res.message);
            history(-1);
          } else {
            toast.error(res.message);
          }

          setLoading(false);
        })
        .catch((err) => {
          alert(err.message);
          toast.error(err.message);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      {" "}
      <BackButton />
      <Card>
        <CardBody>
          {/* {JSON.stringify(form)} */}
          <Form onSubmit={toggle1}>
            <Row>
              <CustomTypeahead
                options={list}
                labelKey="account_name" // Keep this simple for the key lookup
                renderMenuItemChildren={(item) => {
                  let selected = bankList.find(
                    (i) => i.code === item.bank_code
                  );
                  let accountType = accountTypes.find(
                    (i) => i.code === item.account_type
                  );
                  return (
                    <>
                      <div>{item.account_name}</div>
                      <div>
                        {selected.title}({item.account_number})
                      </div>
                      <div>
                        Type: {item.type}({accountType.title}){" "}
                      </div>
                    </>
                  );
                }}
                onChange={(s) => {
                  if (s.length) {
                    let selected = bankList.find(
                      (i) => i.code === s[0].bank_code
                    );
                    setForm((p) => ({
                      ...p,
                      id: s[0].id,
                      account_number: s[0].account_number,
                      account_type: s[0].account_type,
                      bank_code: s[0].bank_code,
                      account_name: s[0].account_name,
                      title: selected.title,
                      type: s[0].type,
                    }));
                  }
                }}
                label={
                  <div>
                    Choose Account <b style={{ color: "red" }}>*</b>
                  </div>
                }
              />
            </Row>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Select Beneficiary Bank <b style={{ color: "red" }}>*</b>
              </Form.Label>
              <Form.Select
                value={form.ben_bank}
                required
                name="ben_bank"
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, ben_bank: value }));
                }}
              >
                <option value="">Select a bank</option>{" "}
                {/* Default empty option */}
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Beneficiary Account Number <b style={{ color: "red" }}>*</b>{" "}
              </Form.Label>
              <Form.Control
                className="form-control"
                required
                type="text"
                maxLength={10}
                value={form.ben_acc}
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, ben_acc: value }));
                }}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Beneficiary Name <b style={{ color: "red" }}>*</b>{" "}
              </Form.Label>
              <Form.Control
                className="form-control"
                required
                type="text"
                name="beneficiary_name"
                value={form.beneficiary_name}
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, beneficiary_name: value }));
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Select Beneficiary Account Type{" "}
                <b style={{ color: "red" }}>*</b>
              </Form.Label>
              <Form.Select
                value={form.ben_acc_type}
                required
                name="ben_acc_type"
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, ben_acc_type: value }));
                }}
              >
                <option value="">Select Account Type</option>{" "}
                {/* Default empty option */}
                {accTypes.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Amount ({formatNumber(form.amount)})
                <b style={{ color: "red" }}>*</b>
              </Form.Label>
              <Form.Control
                required
                type="number"
                className="form-control"
                label="Amount"
                value={form.amount}
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, amount: value }));
                }}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Payment Description <b style={{ color: "red" }}>*</b>
              </Form.Label>
              <Form.Control
                required
                value={form.description}
                onChange={({ target: { value } }) => {
                  setForm((p) => ({ ...p, description: value }));
                }}
              />
            </Form.Group>
          </Form>
          <center className="mt-2">
            <CustomButton
              onClick={() => {
                if (
                  form.account_number === null ||
                  form.amount === null ||
                  form.ben_bank === null ||
                  form.description === null ||
                  form.beneficiary_name === null ||
                  form.ben_acc_type === null ||
                  form.ben_acc === ""
                ) {
                  alert("Please Complete the transfer form");
                } else {
                  toggle1();
                }
              }}
            >
              Send Money
            </CustomButton>
          </center>
        </CardBody>

        <Modal show={modalIsOpen1}>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="mt-2">
                <h5 className="text-">
                  <div>
                    <div>{form.account_name}</div>
                    {form.title}({form.account_number})
                  </div>
                  <div>
                    Type: {form.type}({form.title}){" "}
                  </div>
                  <hr></hr>
                  <div>Beneficiary Account Details</div>
                  <div>Account Number: {form.ben_acc}</div>
                  <div>Bank: {form.ben_acc}</div>
                  <div>
                    Amount:
                    <i>
                      <b>{formatNumber(form.amount)}</b>
                    </i>
                  </div>
                </h5>
              </Col>
              <Row className="text-center">
                <div className=" pb-1 mb-1">
                  <input
                    onChange={({ target: { value } }) => {
                      setPin(value);
                    }}
                    value={pin}
                    name="pin-st"
                    type="password"
                    required
                    maxLength="4"
                    className="form-control"
                    placeholder="Enter PIN"
                  />
                </div>
              </Row>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={balanceLoading}
              size="sm"
              className="bg-danger"
              onClick={toggle1}
            >
              Close
            </Button>
            <CustomButton
              loading={balanceLoading}
              size="sm"
              onClick={() => {
                processPayment();
              }}
            >
              Confirm
            </CustomButton>
          </Modal.Footer>
        </Modal>
      </Card>
    </div>
  );
}

export default DirectTransfer;
