/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  FormGroup,
  FormLabel,
  FormControl,
  FormSelect,
  FormCheck,
  FormText,
  Container,
} from "@themesberg/react-bootstrap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Attachment from "./Attachment";
import { toast } from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import { apiURL, fetchApi, postApi } from "../../../redux/api";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { SearchContext } from "../../HomePage";
import { useSelector } from "react-redux";
import SelectedDataTable from "./SelectedDataTable";
import TextInput from "../../../components/TextInput";
import { PDFViewer } from "@react-pdf/renderer";
import RealiseCollectionPdf from "./ReleaseCollectionPDF";
import { NewRelisePdf } from "./NewReleasePDF";
import useQuery from "../../../hooks/useQuery";
import WarningModal from "./WarningModal";
import CapitalProject from "./CapitalProject";
import NewRecurrentMonthly from "./NewRecurrentMonthly";

function AgReleases({ match, type = null }) {
  const user = useSelector((state) => state.auth.user);
  const history = useNavigate();
  const location = useLocation();

  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };
   const query = useQuery()
   const mdacode = query.get("mda_code");



  const [showAmountWarningModal, setShowAmountWarningModal] = useState(false);
    const [submittingReserveRequest, setSubmittingReserveRequest] =
      useState(false);
 const economicTypeahead = useRef();
  const [showForm, setShowForm] = useState(true);
  const [economicCode, setEconomicCode] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState({});
  const [budgetSummary, setBudgetSummary] = useState([]);
  const mdaRef = useRef();
  const economicRef = useRef();
  const [economicCode1, setEconomicCode1] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [steps, setSteps] = useState("1");
  const imageId = moment().format("YYYYMMDDhhmmss");
  const [form, setForm] = useState({
    collection_date: moment().format("YYYY-MM-DD"),
    approval_date: moment().format("YYYY-MM-DD"),
    mda_description: "",
    mda_budget_balance: "",
    mda_economic_code: "",
    approved_by: "HE",
    approved_amount: "",
    approval: "Approved",
    query_type: "insert",
    imageId,
    narration: "to facilitate the ",
    approval_no: "",
    approval_status: "AG Created",
    add_remark: "",
    sent_to: {
      title: "",
      office: "",
    },
    mda_head: "",
    mda_name:"",
    mda_code:"",
    approval_type: "Recurrent",
    copies: {},
    copies1: {},
  });

  // postApi(`post_approval_collection`, {
  //   form: { query_type: isPending ? "pending" : "by_mda", filter },
  //   // status : "Paid",
  //   // batch_no
  // })

  ///copied
  function getDistinctObjects(array, key1, key2) {
    return array?.filter(
      (obj, index, self) =>
        index ===
        self?.findIndex((o) => o[key1] === obj[key1] && o[key2] === obj[key2])
    );
  }

  // still
  const handleMdaCode = (selected) => {
    if (selected?.length) {
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_code: selectedItem?.mda_code,
        mda_name: selectedItem?.mda_name,
      }));

      getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };
  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        // console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };
  const getList2 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        // console.log(d);
        if (d.result) {
          // console.log(d);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getList();
  }, []);

  const getList5 = useCallback(() => {
    fetchApi(`get-budget-summary?query_type=recurrent`)
      .then((d) => {
        // console.log(d);
        if (d.result) {
          // console.log(d);
          setEconomicCode(d.result);
          economicTypeahead?.current?.focus();
        }
      })
      .catch((err) => console.log(err));
    mdaRef?.current?.focus();
  }, [mdacode]);

  useEffect(() => {
    getList5();
  }, []);

useEffect(() => {console.log(form)}, [form.mda_code])
  //again
  const getList21 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        // console.log(d,'sdfgh');
        if (d.result && d.result.length) {
          // console.log(d);
          setEconomicCode1(d.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleMdaCode1 = (selected) => {
    if (selected?.length) {
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        // mda_code: selectedItem.head,
        mda_description: selectedItem.description,
        // mda_name: selectedItem.description,
        // description:selectedItem.mda_name,
      }));

      getList21(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleEconomicCode = (selected) => {
    if (selected?.length) {
      const selectedItem = selected[0];
      setSelectedBudget(selected[0]);
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        description: selectedItem.economic_description,
        mda_description: selectedItem.economic_description,
      }));
      getBudgetBalance(selectedItem.economic_code);
    } else {
      console.log("no item selected");
    }
  };

  const getBudgetBalance = useCallback(
    (economic_code) => {
      postApi(`update_budget_balance`, {
        query_type: "select",
        mda_name:
          form.projectType === "payable-to" ? form.mda_head : form.mda_name,
        mda_code:
          form.projectType === "payable-to"
            ? form.mda_head_code
            : form.mda_code,
        amount: null,
        economic_code: economic_code,
        geo_code: form.geo_code,
        program_code: null,
        functional_code: form.functional_code,
      })
        .then((d) => {
          console.log("data", d);
          if (d.success) {
            const selectedItem = d.data[0];
            setForm((p) => ({
              ...p,
              description: selectedItem?.budget_description,
              mda_budget_balance:
                selectedItem?.mopb_balance === 0
                  ? "Budget Exausted"
                  : selectedItem?.mopb_balance,
              budget:
                selectedItem?.approve_amount === 0
                  ? "Budget Exausted"
                  : selectedItem?.approve_amount,
            }));
          }
        })
        .catch((err) => {
          // Handle errors
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [form.mda_code, form.mda_head_code, form.projectType]
  );

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      console.log(checked,value)
      setForm((prev) => ({ ...prev, [name]: checked ? value : "" }));
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleAddToList = () => {
    console.log(form)
    if (form.approved_amount === "") {
      toast("Uncomplete form", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      setSelectedData((prevData) => [...prevData, form]);
      setShowTable(true);
      setForm({
        collection_date: moment().format("YYYY-MM-DD"),
        approval_date: moment().format("YYYY-MM-DD"),
        mda_description: "",
        mda_budget_balance: "",
        mda_economic_code: "",
        approved_by: "HE",
        approved_amount: "",
        approval: "Approved",
        query_type: "insert",
        imageId,
        narration: "to facilitate the ",
        approval_no: "",
        approval_status: "Approval Initiated",
        add_remark: "",
        sent_to: {
          title: "",
          office: "",
        },
        mda_head: "",
        approval_type: "Recurrent",
        copies: {},
        copies1: {},
      });
      mdaRef?.current?.setState({ text: "" });
      economicRef?.current?.setState({ text: "" });
    }
  };

    const handleApi = () => {
      let list = selectedData;
      if (
        parseFloat(list[0].approved_amount) >
        parseFloat(list[0].mda_budget_balance)
      ) {
        // setShowAmountWarningModal(true);
      } else {
        if (form.approved_by === "") {
          toast("Please Complete the form", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        } else {
          setIsLoading(true);
          form.sent_to_mda = form.sent_to.office || "";
          form.sent_to_office = form.sent_to.title || "";
          form.query_type = "insert";
          form.approval_status = "Approval Initiated";
          form.collection_date = moment().format("YYYY-MM-DD");
          form.approval_date = moment().format("YYYY-MM-DD");
          postApi(`post_approval_collection`, { list, form })
            .then((data) => {
              if (data.success) {
                console.log(data, "FHDSSS");
                handleApi1(data.approval_no);
                const _newData = selectedData.map((i) => ({
                  ...i,
                  approval_no: data.approval_no,
                  project_type: i.projectType,
                }));
                setSelectedData(_newData);
                toast("submit success", {
                  appearance: "info",
                  autoDismiss: true,
                  autoDismissTimeout: 5000,
                });
                setShowForm(false);
              }
            })
            .catch((err) => {
              alert(err);
              toast(err, {
                appearance: "info",
                autoDismiss: true,
                autoDismissTimeout: 5000,
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    };

     const handleApi1 = (approval_no) => {
       if (form.approved_amount && form.approved_amount !== "") {
         const formData = new FormData();
         form.approval_no = approval_no;
         for (let i = 0; i < acceptedFiles?.length; i++) {
           formData.append("files", acceptedFiles[i]);
         }

         if (!acceptedFiles?.length) {
           setForm((p) => ({ ...p, approval: "not approved" }));
           formData.append("form", JSON.stringify(form));

           fetch(`${apiURL}/post_images`, {
             method: "POST",

             body: formData,
           })
             .then((response) => response.json())
             .then((result) => {
               console.log(result);
             })

             .catch((err) => {
               console.log(err);
             });
         } else {
           formData.append("form", JSON.stringify(form));

           fetch(`${apiURL}/post_images`, {
             method: "POST",

             body: formData,
           })
             .then((response) => response.json())
             .then((result) => {
               console.log(result);
             })

             .catch((err) => {
               console.log(err);
             });
         }
       }
     };
       const submitPlanningReserveRequest = () => {
         setSubmittingReserveRequest(true);
         setIsLoading(true);
         form.sent_to_mda = form.sent_to.office || "";
         form.sent_to_office = form.sent_to.title || "";
         form.query_type = "insert";
         (form.approval_status = "No Budget"),
           postApi(`post_approval_collection`, { form })
             .then((data) => {
               if (data.success) {
                 console.log(data);
                 handleApi1(data.approval_no);

                 postApi(`planning-reservation`, {
                   destMdaCode: selectedBudget.admin_code,
                   destEconomicCode: selectedBudget.economic_code,
                   destMdaDescription: selectedBudget.admin_description,
                   destEconomicDescription: selectedBudget.economic_description,
                   destFundCode: selectedBudget.fund_code,
                   destFundDescription: selectedBudget.fund_description,
                   destFunctionalCode: selectedBudget.function_code,
                   destFunctionalDescription:
                     selectedBudget.function_description,
                   destProgramCode: selectedBudget.program_code,
                   destProgramDescription: selectedBudget.program_description,
                   destGeoCode: selectedBudget.geo_code,
                   destGeoDescription: selectedBudget.geo_description,
                   approvedAmount: form.approved_amount,
                   query_type: "insert",
                   reserveStatus: "Pending",
                   approvalNo: data.approval_no,
                 })
                   .then((data) => {
                     if (data.success) {
                       console.log(data);
                       toast("submit success", {
                         appearance: "info",
                         autoDismiss: true,
                         autoDismissTimeout: 5000,
                       });
                       setShowForm(false);
                       history("/planning-reservation");
                     }
                   })
                   .catch((err) => {
                     toast(err, {
                       appearance: "error",
                       autoDismiss: true,
                       autoDismissTimeout: 5000,
                     });
                   })
                   .finally(() => {
                     setSubmittingReserveRequest(false);
                   });
                 setShowForm(false);
               }
             })
             .catch((err) => {
               toast(err, {
                 appearance: "error",
                 autoDismiss: true,
                 autoDismissTimeout: 5000,
               });
             })
             .finally(() => {
               setIsLoading(false);
             });
       };
         const unClearhandleApiRelease = () => {
           setSubmittingReserveRequest(true);
           if (
             form.mda_name === "" ||
             form.mda_budget_balance == "" ||
             form.mda_code == "" ||
             form.approved_by === ""
           ) {
             alert("Please Complete the form");
             setSubmittingReserveRequest(false);
           } else {
             setIsLoading(true);
             form.sent_to_mda = form.sent_to.office || "";
             form.sent_to_office = form.sent_to.title || "";
             form.query_type = "insert";
             form.approval_status = "Unclear Released";

             postApi(`post_approval_collection`, { form })
               .then((data) => {
                 if (data.success) {
                   console.log(data);
                   handleApi1(data.approval_no);
                   const _newData = selectedData.forEach((i) => ({
                     ...i,
                     approval_no: data.approval_no,
                   }));
                   setSelectedData(_newData);
                   toast("submit success", {
                     appearance: "info",
                     autoDismiss: true,
                     autoDismissTimeout: 5000,
                   });
                   setShowForm(false);
                   setSubmittingReserveRequest(false);
                 }
               })
               .catch((err) => {
                 alert(err);
                 toast(err, {
                   appearance: "info",
                   autoDismiss: true,
                   autoDismissTimeout: 5000,
                 });
                 setShowForm(false);
                 setSubmittingReserveRequest(false);
               })
               .finally(() => {
                 setIsLoading(false);
                 setSubmittingReserveRequest(false);
               });
           }
         };


  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ accept: "image/*, .pdf, text/plain" });

  return (
    <div className="container-fluild">
      {showForm ? (
        <div className="card">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3 pb-0">
            <div className="d-block mb-1 mx-4 mb-md-0">
              <h3>Ag Releases</h3>
            </div>
          </div>

          <Row className="mt-1 px-4 py-4 pt-2">
            <Col md={3}>
              <FormLabel>Realease Type</FormLabel>
              {/* <SelectInput options={["Recurrent Project","Capital Project","Monthly Recurrent","Monthly Critical",]} /> */}
              <FormSelect
                type="select"
                value={steps}
                onChange={({ target: { value } }) => {
                  setSteps(value);
                  if (value === "1") {
                    setForm((p) => ({
                      ...p,
                      approval_type: "Recurrent",
                    }));
                  } else if (value === "2") {
                    setForm((p) => ({ ...p, approval_type: "Capital" }));
                  }
                }}
              >
                <option>--Select--</option>
                <option value="1">Recurrent Project</option>
                <option value="2">Capital Project</option>
                <option value="3">Monthly Recurrent</option>
                <option value="4">Monthly Critical</option>
              </FormSelect>
            </Col>

            {steps === "3" || steps === "4" ? (
              <Col md={6}>
                <FormLabel>Narration</FormLabel>
                <textarea
                  className="form-control column"
                  type="textarea"
                  value={form.narration}
                  name="narration"
                  onChange={handleChange}
                  rows={5}
                />
              </Col>
            ) : null}
            <Col
              md={3}
              style={{ alignItems: "flex-end", display: "inline-flex" }}
            >
              <FormGroup check style={{ fontWeight: "bold", marginTop: 15 }}>
                <FormLabel
                  check
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormCheck
                    id="checkbox2"
                    type="checkbox"
                    style={{ fontSize: 20 }}
                    name="cc"
                    value="kirs" // Set the desired value here
                    checked={form.cc === "kirs"} // Compare form state to the desired value
                    onChange={handleChange}
                  />
                  Copy to Internal Revenue
                </FormLabel>
              </FormGroup>
            </Col>
            {steps === "1" ? (
              <>
                <p>{selectedOptions.title}</p>
                <Col md={4}>
                  <FormLabel>Collection Date</FormLabel>
                  <FormControl
                    type="date"
                    value={form.collection_date}
                    name="collection_date"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Approval Date</FormLabel>
                  <FormControl
                    type="date"
                    value={form.approval_date}
                    name="approval_date"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Approved By</FormLabel>
                  <FormSelect
                    id="exampleSelect"
                    name="approved_by"
                    value={form.approved_by}
                    // type="select"
                    onChange={handleChange}
                  >
                    <option>--Select--</option>
                    <option value="HE">HE</option>
                  </FormSelect>
                </Col>

                <Col md={12}>
                  <FormLabel>Narration</FormLabel>
                  <textarea
                    className="coloum form-control mb-2"
                    type="textarea"
                    value={form.narration}
                    name="narration"
                    onChange={handleChange}
                    rows={5}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Select MDA</FormLabel>
                  <Typeahead
                    size="md"
                    id="20"
                    onChange={(selected) => handleMdaCode(selected)}
                    onInputChange={(text) => console.log(text)}
                    options={getDistinctObjects(
                      budgetSummary,
                      "mda_name",
                      "mda_code"
                    )}
                    labelKey={(option) => `${option.mda_name}`}
                    placeholder="Select---"
                    ref={mdaRef}
                  />
                </Col>

                <Col md={4}>
                  <FormLabel>Administrative Code</FormLabel>
                  <FormControl
                    type="number"
                    value={form.mda_code}
                    name="mda_code"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <FormLabel style={{ display: "flex" }}>
                    Select Head (if any){" "}
                    <span
                      style={{
                        // backgroundColor: "green",
                        borderRadius: 10,
                        display: "flex",
                        alignItems: "center",
                        textWrap: "nowrap",
                        fontSize: 12,
                        padding: 1,
                      }}
                      className="px-2 bg-success text-white"
                    >
                      OR PAYABLE TO
                      <FormCheck
                        id="checkbox3"
                        type="checkbox"
                        style={{ fontSize: 15 }}
                        name="projectType"
                        value="payable-to"
                        checked={form.projectType === "payable-to"}
                        onChange={handleChange}
                      />
                    </span>
                  </FormLabel>
                  <Typeahead
                    size="md"
                    id="20"
                    onChange={(selected) => handleMdaCode1(selected)}
                    onInputChange={(text) => console.log(text)}
                    options={getDistinctObjects(
                      budgetSummary,
                      "mda_name",
                      "mda_code"
                    )}
                    labelKey={(option) => `${option.mda_name}`}
                    placeholder="Select---"
                    // ref={mdaRef}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Select Economic Code</FormLabel>
                  <Typeahead
                    size="md"
                    id="20"
                    onChange={(selected) => handleEconomicCode(selected)}
                    onInputChange={(text) => console.log(text)}
                    options={getDistinctObjects(
                      economicCode,
                      "economic_code",
                      "economic_description"
                    )}
                    labelKey={(option) => {
                      return `${option.economic_code} - ${option.economic_description}`;
                    }}
                    placeholder="Select---"
                    ref={economicRef}
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Description</FormLabel>
                  <FormControl
                    type="text"
                    value={form.description}
                    name="description"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Budget Balance</FormLabel>
                  <FormControl
                    value={
                      isNaN(form.mda_budget_balance)
                        ? form.mda_budget_balance
                        : formatNumber(form.mda_budget_balance)
                    }
                    name="mda_budget_balance"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>Approved Budget Allocation</FormLabel>
                  <FormControl
                    value={
                      isNaN(form.budget)
                        ? form.budget
                        : formatNumber(form.budget)
                    }
                    name="budget"
                    onChange={handleChange}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <FormLabel>
                    Approved Amount ({formatNumber(form.approved_amount)})
                  </FormLabel>
                  <FormControl
                    type="number"
                    value={form.approved_amount}
                    name="approved_amount"
                    onChange={handleChange}
                  />
                </Col>
                {form.mda_code !== "" && form.mda_budget_balance === 0 ? (
                  <Alert color="primary" className="mt-2">
                    <center>Please select the corresponding line item</center>
                  </Alert>
                ) : null}

                <Col md={12} className="py-4 px-4">
                  <Attachment
                    acceptedFiles={acceptedFiles}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                  />
                </Col>
                <center className="mb-3">
                  <Button color="success" onClick={handleAddToList}>
                    {isLoading ? (
                      <Spinner color="light" size="sm" />
                    ) : (
                      "Add to batch"
                    )}
                  </Button>
                </center>
                <SelectedDataTable
                  selectedData={selectedData}
                  showTable={showTable}
                />

                {selectedData?.length ? (
                  <Button
                    color="primary"
                    onClick={handleApi}
                    disabled={isLoading}
                    style={{
                      marginRight: 10,
                    }}
                  >
                    {isLoading ? <Spinner color="light" size="sm" /> : "Submit"}
                  </Button>
                ) : null}
              </>
            ) : steps === "2" ? (
              <CapitalProject generalForm={form} />
            ) : (
              <NewRecurrentMonthly steps={steps} form={form} />
           
            )}
          </Row>
          <WarningModal
            showModal={showAmountWarningModal}
            setShowModal={setShowAmountWarningModal}
            handleSubmit={submitPlanningReserveRequest}
            form={form}
            loading={submittingReserveRequest}
            handleApi={unClearhandleApiRelease}
          />
        </div>
      ) : (
        <>
          {form.approval_type === "Recurrent" && (
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
              {selectedData?.length === 1 ? (
                <RealiseCollectionPdf
                  form={selectedData[0]}
                  selecOffer={formatNumber}
                />
              ) : (
                <NewRelisePdf
                  selectedData={selectedData}
                  selecOffer={formatNumber}
                />
              )}
            </PDFViewer>
          )}
        </>
      )}
    </div>
  );
}

export default AgReleases;
