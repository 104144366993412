import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader,
  Row,
  Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";
// import { Label } from "recharts";
import ProfileUpdatePassword from "./ProfileUpdatePassword";
import { postApi } from "../redux/api";
import SignaturePad from "react-signature-canvas";

export default function Profile() {
  const user = useSelector((state) => state.auth.user);
  const emailInputRef = useRef();
  const [form, setForm] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [imageURL, setImageURL] = useState(null);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleUpdate = () => {
    setIsLoading(true);

    const data = {
      email: form.email,
      phone: form.phone,
      username: form.username,
      fullname: form.fullname,
      signature: imageURL,
      designation: form.designation,
    };

    postApi("treasury-app/update_contact_info", data)
      .then((resp) => {
        if (resp.success) {
          alert("Submit Successful");
          setForm({
            email: "",
            phone: "",
            designation: "",
            fullname: "",
          });
        } else {
          alert("Submit Failed");
        }
      })
      .catch((err) => {
        console.error(err);
        alert("An error occurred");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    toggle();
  };

  useEffect(() => {
    emailInputRef.current.focus();
  }, [user]);

  return (
    <Card className="mt-3" style={{}}>
      <Card.Body>
        <h5 className="mb-4 mb-5">
          <FontAwesomeIcon icon={faUser} className="me-2" /> User Profile
        </h5>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  value={form.fullname}
                  name="fullname"
                  onChange={handleChange}
                  placeholder="Enter your FullName"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  value={form.username}
                  disabled
                  placeholder="Enter your User name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>MDA Name</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Enter your MDA name"
                  value={form.mda_name}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Enter Designation"
                  value={form.designation}
                  name="designation"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="col-md-5 mt-2 mb-2">
                <Button
                  size="sm"
                  className="mt-2"
                  color="danger"
                  onClick={toggle}
                >
                  Create Signature
                </Button>
              </div>
              <div className="col-md-6">
                {imageURL ? (
                  <img
                    src={imageURL}
                    className="shadow mt-1"
                    alt="my signature"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      border: "0.5px solid gray",
                      width: "150px",
                      borderRadius: "10px",
                    }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div
            style={{
              width: 30000,
            }}
          >
            <Modal
              show={open}
              // size=""
              onHide={toggle}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Signature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{
                    width: 470,
                    height: 100,
                    // className: "sigCanvas",
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button color="success" onClick={save}>
                  Save
                </Button>
                <Button color="primary" onClick={clear}>
                  Clear
                </Button>
                <Button color="danger" onClick={toggle}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  size="sm"
                  type="email"
                  ref={emailInputRef}
                  placeholder="Enter your Email Address"
                  value={form.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Phone No.</Form.Label>
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="Enter your Phone number"
                  value={form.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <div style={{ width: "100%", marginTop: 15 }}>
              <center>
                <Button size="sm" onClick={handleUpdate} disabled={isLoading}>
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Update Profile"
                  )}
                </Button>
              </center>
            </div>
          </Row>
          <ProfileUpdatePassword form={form} setForm={setForm} />
        </Form>
      </Card.Body>
    </Card>
  );
}
