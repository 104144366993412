/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import {
  Alert,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Card,
  Container,
  Dropdown,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Routes } from "../../../routes";
import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fetchUserData } from '';
import { faCheck, faEye, faPrint } from "@fortawesome/free-solid-svg-icons";

import { useSelector } from "react-redux";
import { Badge, Col, Row } from "react-bootstrap";
import moment from "moment-timezone";
import { SearchContext } from "../../HomePage";
import { deleteApi, fetchApi, postApi } from "../../../redux/api";
import { formatNumber } from "../../../redux/utils";
import SearchInput from "../../../components/SearchInput";
import CustomTable from "../../../components/CustomTable";

function DirectTransferList({ match, type = null }) {
  const user = useSelector((state) => state.auth.user);
  const history = useNavigate();
  const location = useLocation();
  const isPending = location.pathname.includes("payapp");
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  const [filterText, setFilterText] = useState("");
  const batch_no = useParams().batch_no;
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(type || "All");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedApproval, setApprovalNo] = useState("");
  const [returndApproval, retApprovalNo] = useState("");

  const [approvalImageInfo, setApprovalImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);

  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));

  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };
  const [showRemark1, setShowRemark1] = useState(false);
  const [showRemark, setShowRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [returnlLoading, setReturnLoading] = useState(false);
  const [returnlMessage, setReturnMessage] = useState(null);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [selectedReleases, setSelectedReleases] = useState([]);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [preparingSchedule, setPreparingSchedule] = useState(false);

  const toggleRemark = () => setShowRemark((p) => !p);
  const [loading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    fetchApi(
      `direct-transfer/transactions?date_from=${dateFrom}&date_to=${dateTo} `
    )
      .then((resp) => {
        setIsLoading(false);
        if (resp) {
          setList(resp.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [dateTo, dateFrom]);
  const fields = [
    {
      title: "Date",
      custom: true,
      component: (item) => (
        <div className="text-center">
          {moment(item.createdAt).format("YYYY-MM-DD")}
        </div>
      ),
      //   value: "payment_batch_id",
    },
    {
      title: 'Payment Description',
      custom: true,
      component: (item, index) => <div>{item.description}</div>,
    },
    {
      title: "Amount",
      //   value: "approvals",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },

    {
      title: "Status",
      custom: true,
      component: (item) => (
        <div className="text-center">
          <Badge>{item.status}</Badge>
        </div>
      ),
    },
    // {
    //     title: "Action",
    //     custom:true,
    //     component: item => (
    //         <div className="text-center">
    //             <Button
    //             onClick={() =>
    //               history(
    //                 `${Routes.paymentRequest.viewPaymentRequestDetailFinal}/${item.payment_batch_id}`
    //               )
    //             }
    //           >
    //             <FontAwesomeIcon icon={faEye} className="me-1" />
    //             View
    //           </Button>
    //         </div>
    //     )
    // }
  ];

  const modalFields = [
    {
      title: "Approval No",
      value: "approval_no",
    },
    {
      title: "Release Date",
      value: "approval_date",
    },
    {
      title: "MDA Name",
      value: "mda_name",
    },

    {
      title: "Amount",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
    },
  ];

  // const ApprovalCollectionIndex = () => {
  //   const { searchTerm, setSearchTerm } = useContext(SearchContext);
  //   // ... rest of the code
  // };

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  const totalReleases = selectedReleases.reduce(
    (a, b) => a + parseFloat(b.amount),
    0
  );

  return (
    <>
      {/* {JSON.stringify(list)} */}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Button
          onClick={() => {}}
          className="me-2 bg-success"
        >
          Add Approval
        </Button> */}

        <h4>
          {/* <FontAwesomeIcon icon={faListUl} className="me-2" />  */}
          Direct Transfer
        </h4>

        {/* {JSON.stringify(searchTerm)} */}
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <h5>Total Amount Selected: {totalReleases.toLocaleString()}</h5>
        </div> */}
      </div>
      {/* <SearchInput
        placeholder="Search For Releases"
        value={searchTerm}
        onChange={({ target: { value } }) => setSearchTerm(value)}
      /> */}

      <Row>
        <Col
          md={6}
          style={{
            marginTop: 28,
          }}
        >
          {/* <SearchInput
            placeholder="Search For All Schedule"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          /> */}
          <Button
            onClick={() => history(`${Routes.paymentRequest.directTransfer}`)}
          >
            Transfer
          </Button>
        </Col>
        <Col md={6}>
          <Row
            style={{
              marginBottom: 10,
            }}
          >
            <Col md={6}>
              <Form.Group id="date_from">
                <Form.Label>Date From</Form.Label>
                <Form.Control
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="date_to">
                <Form.Label>Date to</Form.Label>
                <Form.Control
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0">
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ height: "60vh", overflow: "scroll" }}>
              <CustomTable fields={fields} data={list} />
            </div>
          )}
          {/* {JSON.stringify(finalList)} */}
        </Card.Body>

        {/* <Modal size="xl" show={scheduleModalOpen} onHide={() => setScheduleModalOpen(p=>!p)}>
            <Modal.Header>Preview Schedule</Modal.Header>
            <Modal.Body>
            <CustomTable
                fields={modalFields}
                data={selectedReleases}
              />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={prepareSchedule}><FontAwesomeIcon icon={faCheck} /> Continue</Button>
            </Modal.Footer>
        </Modal> */}

        {/* {budgetUtilization.length ? <center>
            <CustomButton loading={preparingSchedule} onClick={() => setScheduleModalOpen(true)}>Raise Payment Request</CustomButton>
        </center> : null} */}
      </Card>
    </>
  );
}

export default DirectTransferList;
