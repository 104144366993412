import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
// import { toast } from ' react-hot-toast';
// import { Toaster } from "react-hot-toast";
// import ' react-hot-toast/dist/ReactToastify.css';
// core styles
import './scss/volt.scss';
import 'react-datetime/css/react-datetime.css';
import 'react-credit-cards/es/styles-compiled.css';
import 'dotenv/config';

import HomePage from './pages/HomePage'
import ScrollToTop from './components/ScrollToTop'
import store from './redux/store'
import { Toaster } from 'react-hot-toast'
// import Notifications from './Notifications';
// import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <Toaster />
      <HomePage />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)
