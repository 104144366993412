import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Card,
  Button,
  Form,
  Modal,
  Container,
  Row,
} from "@themesberg/react-bootstrap";
import Attachment from "../../../components/Attachment";
import { toast } from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { Typeahead } from "react-bootstrap-typeahead";
import useQuery from "../../../hooks/useQuery";
import { apiURL, fetchApi, postApi } from "../../../redux/api";
import { Routes } from "../../../routes";
import BackButton from "../../../components/BackButton";
import CustomForm from "../../../components/CustomForm";
import { formatNumber } from "../../../redux/utils";
import CustomButton from "../../../components/CustomButton";
import NewContractorForm from "./NewContractorForm";
import { PaymentVoucherPdf } from "./PVPDF";
import { PDFViewer } from "@react-pdf/renderer";
import TaxItem from "../contractor-schedule/TaxItem";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import NewPVBankDetails from "./NewPVBankDetails";
import { faEye } from "@fortawesome/free-regular-svg-icons";

export const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

export const paymentTypes = ["Direct Payment", "Power of Attorney", "Others"];
export const projectTypes = [
  "Special Project",
  "Constituency Project",
  "Other",
];
export const projectClassifications = [
  "Certificate Number",
  "APG Number",
  "Mobilization",
  "Retention",
  "Land and Building",
  "Plant and Machinery",
  "Furniture and Fittings",
  "Others",
];

export default ({ match }) => {
  const [
    pvCode,
    // setPvCode
  ] = useState("");
  const [showPDF, setShowPDF] = useState(false);
  const mdaCodeTypeahead = useRef();
  const [modal, setModal] = useState(false);
  const [mdaSourceAcctlist, setMdaSourceAcctlist] = useState([]);
  const history = useNavigate();
  const [batchCode, setBatchCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [taxesList, setTaxesList] = useState([]);
  const [taxesApplied, setTaxesApplied] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const toggle = () => {
    setModal(!modal);
    if (modal === true) {
      history("/dashboard/pv_collection");
    }
  };
  const [pvImageInfo, setPvImageInfo] = useState([]);
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const query = useQuery();
  const imageId1 = query.get("imageId");
  const _id = query.get("id");
  // const approval_type = query.get("approval_type");
  const imageId = moment().format("YYYYMMDDhhmmss");
  const approval_no = useParams().approval_no;

  const [form, setForm] = useState({
    pv_code: "",
    date: moment().format("YYYY-MM-DD"),
    project_type: "",
    payment_type: "",
    mda_name: "",
    amount: "",
    query_type: "insert",
    imageId,
  });
  const approval_type = /*form?.approval_type || ""*/ query.get("approval_type");

  useEffect(() => {
    postApi(`post_taxes`, {
      query_type: "select",
    })
      .then((data) => {
        if (data.result) {
          setTaxesList(data.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const [mda_data, setMda_data] = useState([]);

  useEffect(() => {
    if (approval_no) {
      postApi(`post_approval_collection`, {
        form: {
          query_type: "select_by_approval_no",
          filter: "Capital",
          approval_no,
        },
      })
        .then((data) => {
          if (data && data.result && data.result.length) {
            setMda_data(data.result);
            setForm((p) => ({ ...p, ...data.result[0] }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [approval_no]);

  const handleReset = () => {
    setForm((p) => ({
      ...p,
      date: "",
      project_type: "",
      payment_type: "",
      mda_name: "",
      amount: "",
    }));

    // clearEconomicTypeahead()
    // clearMdaTypeahead()
  };
  const params = useParams();
  const id = params.id;

  const param = Object.entries(params).length;

  const updateBudgetCode = (description, query_type) => {
    postApi(`update-budget-code`, {
      description,
      query_type,
    })
      .then((d) => {
        console.log(d);
        if (d.result) {
          setBatchCode(d.pv_code);
          console.log("batchCode", batchCode);
        }
      })
      .catch((err) => console.log(err));
  };

  const getList = () => {
    // fetch(`${api}/get_mdabank_details?query_type=select`)
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setMdaSourceAcctlist(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleApi1 = (pv_no) => {
    // console.log(form)

    // if (form.approved_amount && form.approved_amount !== '') {
    const formData = new FormData();
    form.pv_no = pv_no;
    for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append("files", acceptedFiles[i]);
    }

    if (!acceptedFiles.length) {
      setForm((p) => ({ ...p, approval: "not approved" }));
      formData.append("form", JSON.stringify(form));
      // // formData.append('event_name', options[0].event_name)

      fetch(`${apiURL}/post_images_pv`, {
        method: "POST",

        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          // handleApi()
          // setLoadSpinner(false)
          console.log(result);
          // removeAll()
          // toggle()
          // let acceptedFiles = []

          // localStorage.setItem("key", JSON.stringify(result.token));
          // setName(result.user)
          // history("/homepage/payment_table");

          // console.log(result);
        })

        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false)
        });
    } else {
      formData.append("form", JSON.stringify(form));
      // // formData.append('event_name', options[0].event_name)

      fetch(`${apiURL}/post_images_pv`, {
        method: "POST",

        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          // handleApi()
          // setLoadSpinner(false)
          console.log(result);
          // removeAll()
          // toggle()
          // let acceptedFiles = []

          // localStorage.setItem("key", JSON.stringify(result.token));
          // setName(result.user)
          // history("/homepage/payment_table");

          // console.log(result);
        })

        .catch((err) => {
          console.log(err);
          // alert(err);
          // setLoadSpinner(false)
        });
    }
    // Object.keys(form).forEach(item => {
    //   formData.append(item, form[item])
    // })

    // formData.append('user', name.email)
    //  formData.append('group_id', groupName)
    // } else {
    //   alert('Please enter a valid amount')
    // }
  };

  const handleApi = () => {
    setLoading(true);
    // Receiving Office Approved
    postApi(`post_pv_collection`, {
      form: {
        ...form,
        approval_no,
        userId: user.username,
      },
      list: mda_data.map((d) => ({
        approval_no,
        userId: user.username,
        ...form,
        ...d,
        account_name: form.mda_account_name,
        bank_name: form.mda_account_bank_name,
        account_type: form.mda_account_type,
        sort_code: form.mda_account_sort_code,
        account_number: form.mda_acc_no||form.mda_account_no,
        sub: form.sub,
        sub_sub: form.sub_sub,
        sub_sub_sub: form.sub_sub_sub,
        payment_to: form.payment_to,
      })),
      taxesApplied,
      notif_key: "Check PV",
      // status: 'pending',
    })
      .then((data) => {
        // console.log(data, '1')
        if (data.success) {
          handleApi1(data.approval_no);
          updateBudgetCode("pv_code", "update");
          toast.success("Submitted successfully!");
          setBatchCode(data.pv_code);
          console.log(data);
          toggle();

          // if this pv is created from OAG, update
          // if (user.mda_name === "Office of the Accountant-General") {
          //   postApi(`update-pv`, {
          //     pv_code: data.pv_code,
          //     approval_no: approval_no,
          //     status: "Receiving Office Approved",
          //     userId: user.username,
          //     remarks:'',
          //   });
          //   history(`${Routes.pv.pvReportView}/${approval_no}?approval_type=${approval_type}`)
          // } else {
            history(
              `${Routes.cashBacking.NewViewPVPDf}/${approval_no}?approval_type=${approval_type}`
            );
          // }
        } else {
          toast.error("An error occurred");
        }
      })
      .then((data) => {
        // console.log(data, "2");

        if (data.success) {
          handleApi1(data.approval_no);
          updateBudgetCode("pv_code", "update");
          alert("Submitted successfully!");
          setBatchCode(data.pv_code);
          console.log(data);
          toggle();
          setShowPDF(true);
          // handleReset();
          // history(Routes.pv.pvlIndex);
        } else {
          alert("An error occurred");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleMdaCode = (selected) => {
    if (selected.length) {
      console.log("selected", selected);
      const selectedItem = selected[0];
      // setOptions(selected)
      setForm((p) => ({
        ...p,
        mda_name: selectedItem.mda_name,
        mda_acc: selectedItem.mda_code,
        // : selectedItem.mda_name,
        // mda_economic_code : selectedItem.economic_code
      }));

      // getList2(selectedItem.mda_code)
    } else {
      console.log("no item selected");
    }
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*, .pdf, text/plain" });

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };

  const viewAttachment = () => {
    fetchApi(`fetch-pv-images?pv_no=${imageId1}`, (resp) => {
      attachmentModalToggle();
      if (resp.results && resp.results.length) {
        setPvImageInfo(resp.results);
      } else {
        setPvImageInfo({});
      }
    });
  };

  return (
    <>
      <BackButton />
      <Card>
        <Card.Body>
          <div className="d-flex flex-direction-row justify-content-between">
          <div className="mb-4">
          <h5>New PV Collection</h5>
          <h6>Approval No.: {approval_no}</h6>
          </div>
          <button
                style={{
                  backgroundColor: "yellow",
                  border: "none",
                  height: "60%",
                  //   padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() =>
                  history(
                    `${Routes.cashBacking.releaseCollectionPDF}/${approval_no}`
                  )
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-1" />
                View Release Document
              </button>
              </div>
          {showPDF ? (
            <PDFViewer height="1000" width="1000">
              <PaymentVoucherPdf
                form={form}
                user={user}
                data={mda_data}
                taxesList={taxesList}
                taxesApplied={taxesApplied}
              />
            </PDFViewer>
          ) : (
            <>
              <Form>
                {approval_type === "Recurrent" ? (
                  <CustomForm
                    handleChange={handleChange}
                    fields={[
                      {
                        label: "Date",
                        value: form.date,
                        name: "date",
                        type: "date",
                      },
                      // {
                      //   label: "PV Numbers",
                      //   value: form.pv_code,
                      //   name: "pv_code",
                      // },

                      // {
                      //   label: "Enter Certificate Number",
                      //   value: form.certificate_no,
                      //   name: "certificate_no",
                      //   type:
                      //     form.project_classification === "Certificate Number"
                      //       ? "text"
                      //       : "hidden",
                      // },

                      {
                        // type: "custom",
                        // component: (item) => (
                        //   <>
                        //     <Form.Label>Select MDA Name</Form.Label>
                        //     <Typeahead
                        //       size="sm"
                        //       // disabled={id ? true : false}
                        //       id="20"
                        //       onChange={(selected) => handleMdaCode(selected)}
                        //       onInputChange={(text) => console.log(text)}
                        //       options={mdaSourceAcctlist}
                        //       labelKey={(option) => `${option.mda_info}`}
                        //       // labelKey={(option) => `${option.account_name}`}
                        //       placeholder="Select---"
                        //       ref={mdaCodeTypeahead}
                        //     />
                        //   </>
                        // ),
                        label: "MDA Name",
                        value: form.mda_name,
                        name: "account_description",
                        disabled: true,
                      },

                      {
                        label: "Admin Code",
                        value: form.mda_code,
                        name: "mda_code",
                        disabled: true,
                      },
                      // {
                      //   label: `Amount`,
                      //   value: formatNumber(form.amount),
                      //   name: "amount",
                      //   disabled: true,
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "description",
                      //   disabled: true
                      // },
                      // {
                      //   label: "Economic Code",
                      //   value: form.economic_code,
                      //   name: "economic",
                      //   // disabled: true,
                      // },
                      // {
                      //   label: "Discription",
                      //   value: form.description_code,
                      //   name: "economic",
                      //   disabled: true,
                      // },
                      // {
                      //   label: "Fund Code",
                      //   value: form.economic_code,
                      //   name: "economic",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },

                      // {
                      //   label: "Functional Code",
                      //   value: form.economic_code,
                      //   name: "functional",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },
                      // {
                      //   label: "Programme Code",
                      //   value: form.programme_code,
                      //   name: "programme",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },
                      // {
                      //   label: "Geo Code",
                      //   value: form.geo_code,
                      //   name: "geo",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },
                    ]}
                  />
                ) : (
                  <CustomForm
                    handleChange={handleChange}
                    fields={[
                      {
                        label: "Date",
                        value: form.date,
                        name: "date",
                        type: "date",
                        // hidden: true,
                      },
                      // {
                      //   label: "PV Number",
                      //   value: form.pv_code,
                      //   name: "pv_code",
                      // },

                      {
                        label: "Project Type",
                        name: "project_type",
                        value: form.project_type,
                        // options: projectTypes,
                        // type: "select",
                        col: 4,
                        disabled: true,
                      },
                      {
                        label: "Payment Type",
                        name: "payment_type",
                        value: form.payment_type,
                        // options: paymentTypes,
                        // type: "select",
                        col: 4,
                        disabled: true,
                      },
                      {
                        label: "Payment Class",
                        // name: "project_classification",
                        value: form.payment_class,
                        // value: form.project_classification,
                        // options: projectClassifications,
                        // type: "select",
                        col: 4,
                        disabled: true,
                      },
                      // {
                      //   label: "Enter Certificate Number",
                      //   value: form.certificate_no,
                      //   name: "certificate_no",
                      //   type:
                      //     form.project_classification === "Certificate Number"
                      //       ? "text"
                      //       : "hidden",
                      // },

                      {
                        // type: "custom",
                        // component: (item) => (
                        //   <>
                        //     <Form.Label>Select MDA Name</Form.Label>
                        //     <Typeahead
                        //       size="sm"
                        //       // disabled={id ? true : false}
                        //       id="20"
                        //       onChange={(selected) => handleMdaCode(selected)}
                        //       onInputChange={(text) => console.log(text)}
                        //       options={mdaSourceAcctlist}
                        //       labelKey={(option) => `${option.mda_info}`}
                        //       // labelKey={(option) => `${option.account_name}`}
                        //       placeholder="Select---"
                        //       ref={mdaCodeTypeahead}
                        //     />
                        //   </>
                        // ),
                        label: "MDA Name",
                        value: form.mda_name,
                        name: "account_description",
                        disabled: true,
                      },
                      {
                        label: "Admin Code",
                        value: form.mda_code,
                        name: "mda_code",
                        disabled: true,
                      },

                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "description",
                      //   disabled: true
                      // },
                      {
                        label: "Economic Code",
                        value: form.economic_code,
                        name: "economic",
                        // disabled: true,
                      },
                      {
                        label: "Discription",
                        value: form.description_code,
                        name: "economic",
                        disabled: true,
                      },
                      {
                        label: `Amount`,
                        value: formatNumber(form.amount),
                        name: "amount",
                        disabled: true,
                      },
                      // {
                      //   label: "Fund Code",
                      //   value: form.economic_code,
                      //   name: "economic",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },

                      // {
                      //   label: "Functional Code",
                      //   value: form.economic_code,
                      //   name: "functional",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },
                      {
                        label: "Programme Code",
                        value: form.program_code,
                        name: "programme",
                      },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },
                      // {
                      //   label: "Geo Code",
                      //   value: form.geo_code,
                      //   name: "geo",
                      // },
                      // {
                      //   label: "Description",
                      //   value: form.description,
                      //   name: "economic",
                      //   disabled: true
                      // },
                    ]}
                  />
                )}
                {approval_type === "Recurrent" ? (
                  <Table bordered striped className="mt-2">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Economic Code</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mda_data &&
                        mda_data.map((item, index) => (
                          <tr>
                            <td>{item.description_code}</td>
                            <td>{item.economic_code}</td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumber(item.amount)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : null}

                {/* {JSON.stringify(acceptedFiles)} */}
                {!id ? (
                  <div className="my-4">
                    <Attachment
                      acceptedFiles={acceptedFiles}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      isDragActive={isDragActive}
                      isDragAccept={isDragAccept}
                      isDragReject={isDragReject}
                    />
                  </div>
                ) : null}
                {_id ? (
                  <Button variant="link" onClick={viewAttachment}>
                    View Attachment
                  </Button>
                ) : null}

                {approval_type === "Recurrent" ? (
                  <NewPVBankDetails form={form} setForm={setForm} />
                ) : (
                  <>
                    <NewContractorForm form={form} setForm={setForm} />
                    <Card body className="my-2">
                      <h6>Applicable Taxes</h6>
                      <Row>
                        {taxesList.map((item, index) => (
                          <TaxItem
                            key={index}
                            index={index}
                            item={item}
                            form={form}
                            taxesApplied={taxesApplied}
                            setTaxesApplied={setTaxesApplied}
                          />
                        ))}
                      </Row>
                    </Card>
                  </>
                )}

                <center className="mt-2">
                  <CustomButton
                    className="bg-success text-white px-2"
                    onClick={handleApi}
                    loading={loading}
                  >
                    Create Payment Voucher 
                  </CustomButton>
                </center>
              </Form>
              <ViewImage
                attachmentModalIsOpen={attachmentModalIsOpen}
                attachmentModalToggle={attachmentModalToggle}
                pvImageInfo={pvImageInfo}
                imageExtensions={imageExtensions}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export const ViewImage = ({
  attachmentModalIsOpen = false,
  attachmentModalToggle = (f) => f,
  pvImageInfo = [],
  imageExtensions = [],
}) => {
  return (
    <Modal show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
      <Modal.Header show={attachmentModalIsOpen} onHide={attachmentModalToggle}>
        PV No.: {pvImageInfo.pv_no}
      </Modal.Header>
      <Modal.Body className="text-center">
        <Container>
          <div className="image-container">
            {pvImageInfo.length ? (
              pvImageInfo.map((item, index) =>
                imageExtensions.some((extension) =>
                  item.image_url.toLowerCase().endsWith(extension)
                ) ? (
                  <Card.Img
                    top
                    src={`${apiURL}/uploads/${item.image_url}`}
                    alt={"pv"}
                  />
                ) : (
                  <Card.Body>
                    <Card.Link
                      href={`${apiURL}/uploads/${item.image_url}`}
                      target="_blank"
                    >
                      View PDF
                    </Card.Link>
                  </Card.Body>
                )
              )
            ) : (
              <h4>No attachment found.</h4>
            )}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
