/* eslint-disable no-unused-vars */
import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";

import { useState } from "react";
import { useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { apiURL, deleteApi, fetchApi, postApi } from "../../../redux/api";
import CustomTable from "../../../components/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faFastBackward,

} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { formatNumber } from "../../../redux/utils";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import toast from "react-hot-toast";
import TextInput from "../../../components/TextInput";

function ReceivingOfficePvListView({ match, type = null }) {
  const history = useNavigate();
  const location = useLocation();
  const isReceiving = location.pathname.includes("receiving-office");
  const isDirExpControl = location.pathname.includes("dir-exp-control");
  const isDirFinalExpControl = location.pathname.includes(
    "dir-exp-final-control"
  );
  const isAssDirExpControl = location.pathname.includes(
    "assistant-director-exp-control"
  );
  const isAssDirFinalExpControl = location.pathname.includes(
    "assistant-director-exp-final-control"
  );
  const isDag = location.pathname.includes("dag");
  const isExpControl = location.pathname.includes("expenditure-control");
  const user = useSelector((state) => state.auth.user);
  // const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
  // const [filterText, setFilterText] = useState("");
  const approval_no = useParams().approval_no;
  const [pvList, setPvList] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState(type || "All");
  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState(false);
  const [selectedPV, setSelectedPvNo] = useState("");
  const [pvImageInfo, setPvImageInfo] = useState([]);
  const [item, setItem] = useState({});
  const [_delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleDelete = (item) => {
    setItem(item);
    setDelete(!_delete);
  };
  const [showWarning, setShowWarning] = useState(false);
  const [remarks, setRemarks] = useState("");
  //   const [approvalList, setApprovalList] = useState([]);

  const attachmentModalToggle = () => {
    setAttachmentModalIsOpen((p) => !p);
  };

  const viewAttachment = (imageid, pv) => {
    setSelectedPvNo(pv);
    fetchApi(`fetch-pv-images?pv_no=${imageid}`).then((resp) => {
      attachmentModalToggle();
      if (resp.results && resp.results.length) {
        setPvImageInfo(resp.results);
      } else {
        setPvImageInfo({});
      }
    });
  };

  const getPvCollection = useCallback(() => {
    setLoading(true);
    postApi(`get_pv_collection`, {
      form: {
        query_type: "pv_by_approval",
        pv_status: 
        // isDirExpControl
          // ? "Receiving Office Approved"
          // : isAssDirExpControl
          // ? "Director Expenditure Control Approved"
          // : 
          isExpControl
          ? "Receiving Office Approved"
          : isAssDirFinalExpControl
          ? "Expenditure Control Approved"
          : isDirFinalExpControl
          ? "Expenditure Control Approved"
          // ? "Assistant Director Expenditure Control Final Approved"
          : isDag
          ? "Director Expenditure Control Final Approved"
          : "Approved",
        approval_no,
      },
    })
      .then((data) => {
        if (data.result) {
          const arr = [];
          data.result.forEach((item) => {
            arr.push({ ...item, isOpen: false });
          });
          setPvList(data.result);
        }
      })

      .catch((err) => {
        // alert(err);
        // setLoadSpinner(false);
      })
      .finally(() => setLoading(false));
  }, [approval_no, isAssDirExpControl, isDirExpControl, isExpControl]);

  useEffect(() => {
    getPvCollection();
  }, [getPvCollection]);

  const toggleWarning = () => setShowWarning((p) => !p);

  const handleReturn = () => {
    for (let pv of pvList) {
      postApi(`update-pv`, {
        //   query_type: "update_cash_backing",
        pv_code: pv.pv_code,
        approval_no: pv.pv_code,
        status: isDirExpControl
          ? "Returned from Director Expenditure Control"
          : isAssDirExpControl
          ? "Returned from Assistant Director Expenditure Control"
          : isExpControl
          ? "Returned from Expenditure Control"
          : isAssDirFinalExpControl
          ? "Final Approval Denied by Assistant Director Expenditure Control"
          : isDirFinalExpControl
          ? "Final Approval Denied by Director Expenditure Control"
          : isDag
          ? "DAG Approval Denied"
          : "Returned from Receiving Office",
        userId: user.username,
        remarks: '',
      });
    }

    toggleWarning();
    toast.success("PV Returned Successfully!");
    if (isDirExpControl) {
      history(Routes.expenditureControl.dirExpIndex);
    } else if (isDirFinalExpControl) {
      history(Routes.expenditureControl.dirExpFinalIndex);
    } else if (isAssDirExpControl) {
      history(Routes.expenditureControl.assDirExpIndex);
    } else if (isAssDirFinalExpControl) {
      history(Routes.expenditureControl.assDirFinalExpIndex);
    } else if (isExpControl) {
      history(Routes.expenditureControl.expControlIndex);
    } else if (isDag) {
      history(Routes.dag.dagPendingPVIndex);
    } else {
      history(Routes.expenditureControl.receivingOfficeIndex);
    }
  };
console.log(`is dag ${isDag}, is final ${isDirFinalExpControl}`); 
  const handleApprove = () => {
    for (let pv of pvList) {
      postApi(`update-pv`, {
        pv_code: pv.pv_code,
        approval_no: pv.pv_code,
        status: isDirExpControl
          ? "Director Expenditure Control Approved"
          : isAssDirExpControl
          ? "Assistant Director Expenditure Control Approved"
          : isExpControl
          ? "Expenditure Control Approved"
          : isAssDirFinalExpControl
          ? "Assistant Director Expenditure Control Final Approved"
          : isDirFinalExpControl
          ? "Director Expenditure Control Final Approved"
          : isDag
          ? "DAG Approved"
          : "Receiving Office Approved",
        userId: user.username,
        remarks,
        notif_key: isReceiving
          ? "Expenditure Control"
          : isExpControl
          ? "Review PV (ADEC)"
          : isAssDirFinalExpControl
          ? "Review PVs"
          : isDirFinalExpControl
          ? "Approve PVs"
          : isDag
          ? "Payment Schedule"
          : "",
        mda_name: "Office of the Accountant-General",
      });

      if(pv.mda_name === "Office of the Accountant-General" && !isAssDirExpControl) {
        postApi('log_pv_status_history', {
          pv_code: pv.pv_code,
          status: isExpControl ? "Checked" : (isDirExpControl || isDirFinalExpControl) ? "Reviewed" : isDag ? "Approved" : '', 
          userId: user.username, 
          remarks
        })
      }
    }

    toast.success("PV Received Successfully");
    if (isDirExpControl) {
      history(Routes.expenditureControl.dirExpIndex);
    } else if (isDirFinalExpControl) {
      history(Routes.expenditureControl.dirExpFinalIndex);
    } else if (isAssDirExpControl) {
      history(Routes.expenditureControl.assDirExpIndex);
    } else if (isAssDirFinalExpControl) {
      history(Routes.expenditureControl.assDirFinalExpIndex);
    } else if (isDag) {
      history(Routes.dag.dagPendingPVIndex);
    }else if (isExpControl) {
      history(Routes.expenditureControl.expControlIndex)
    } else {
      history(Routes.expenditureControl.receivingOfficeIndex);
    }
  };

  const fields = [
    {
      title: "PV CODE",
      custom: true,
      component: (item) => <div className="text-center">{item.pv_code}</div>,
    },
    {
      title: "PV DATE",
      value: "pv_date",
    },
    {
      title: "MDA Name",
      value: "mda_name",
    },
    {
      title: "Admin Code",
      value: "mda_code",
    },
    {
      title: "Economic Code",
      value: "economic_code",
    },
    {
      title: "Description",
      value: "economic_description",
    },

    // {
    //   title: "Number of PVs",
    //   custom: true,
    //   value: "no_of_pvs",
    //   component: (item) => (
    //     <div className="text-end">{formatNumber(item.no_of_pvs)}</div>
    //   ),
    // },
    // {
    //   title: "Status",
    //   value: "status",
    // },
    {
      title: "Amount (N)",
      custom: true,
      component: (item) => (
        <div className="text-end">{formatNumber(item.amount)}</div>
      ),
      //   value: 'amount',
    },
    {
      title: "Action",
      custom: true,
      component: (item, index) => (
        <Button
          onClick={() => {
            // if(isReview) {
            //     history(
            //         `${Routes.pv.viewPvReview}/${item.approval_no}?approval_type=${item.approval_type}`
            //       )
            // } else if (isApprove) {
            history(
              `${Routes.pv.pvReportView}/${item.approval_no}?approval_type=${item.approval_type}`
            );
            // }
          }}
        >
          View PV Document
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Button
            onClick={() => history(Routes.pv.newPv)}
            className="me-2"
          > <FontAwesomeIcon icon={faPlus} className='me-2' />
            Add PV
          </Button> */}
        <div className="d-block mb-4 mb-md-0">
          <h4>
            {isDirExpControl || isDirFinalExpControl
              ? "Director Expenditure Control"
              : isAssDirExpControl || isAssDirFinalExpControl
              ? "Assistant Director Expenditure Control"
              : isExpControl
              ? "Expenditure Control"
              : isDag
              ? "Deputy Accountant General"
              : "Receiving Office View"}
             
          </h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      {/* {JSON.stringify({pvList, user})} */}
      {pvList.length ? (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
          body
        >
          <Row>
            <Col md={4}>
              <p className="fw-bold">Approval No.: {pvList[0].approval_no}</p>
            </Col>
            <Col className="d-flex flex-direction-row justify-content-end">
              <button
                style={{
                  backgroundColor: "yellow",
                  border: "none",
                  // height: "60%",
                  //   padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                className="me-2"
                onClick={() =>
                  history(
                    `${Routes.cashBacking.releaseCollectionPDF}/${pvList[0].approval_no}`
                  )
                }
              >
                <FontAwesomeIcon icon={faEye} className="me-1" />
                View Release Doc
              </button>
              <Button className="btn-danger me-2" onClick={toggleWarning}>
                <FontAwesomeIcon icon={faFastBackward} className="me-2" />{" "}
                Return to me
              </Button>
              <CustomButton onClick={handleApprove}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Approve
              </CustomButton>
            </Col>
          </Row>
        </Card>
      ) : null}
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body
          className="p-0"
          style={{ height: "80vh", overflow: "scroll" }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <CustomTable
              fields={fields}
              data={pvList}
              // currentPage={currentPage}
              // handlePageChange={handlePageChange}
            />
          )}
        </Card.Body>
        <center className="my-2">
          <Button className="btn-danger me-2" onClick={toggleWarning}>
            <FontAwesomeIcon icon={faFastBackward} className="me-2" /> Return to me
          </Button>
          <CustomButton onClick={handleApprove}>
            <FontAwesomeIcon icon={faCheck} className="me-2" /> Approve
          </CustomButton>
        </center>

        <Modal show={showWarning} onShow={toggleWarning}>
          <Modal.Header>Add Remarks</Modal.Header>
          <Modal.Body>
            <h4>Please provide reason for return</h4>
            <TextInput
              value={remarks}
              onChange={({ target: { value } }) => setRemarks(value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <CustomButton onClick={handleReturn}>Return</CustomButton>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  );
}

export default ReceivingOfficePvListView;
